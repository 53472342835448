import React from 'react';

const MountedAppContext = React.createContext<{ mounted: boolean; setMounted: (set: boolean) => void }>({
  mounted: false,
  setMounted: () => {}
});

export const MountedAppProvider: React.FC<{}> = props => {
  const [mounted, setMounted] = React.useState(false);

  return <MountedAppContext.Provider value={{ mounted, setMounted }}>{props.children}</MountedAppContext.Provider>;
};

export const useMountedApp = () => React.useContext(MountedAppContext);
